export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path === '/login' || to.path === '/fr/login') {
    console.log('login middleware...')

    const { user } = useAuthStore()
    const config = useRuntimeConfig()
    const nuxtApp = useNuxtApp()
    const locale = nuxtApp.$i18n.locale.value

    // Redirect to /auth/me if user is logged in
    if (user && !to.query.benchmark) {
      if (locale === 'de') {
        return navigateTo('/auth/me')
      }
      return navigateTo(`/${nuxtApp.$i18n.locale.value}/auth/me`)
    }

    // redirect to benchmark if its a request for benchmark

    if (user && to.query.benchmark) {
      if (process.client) {
        window.location.href = `${config.public.API_URL}/api/benchmark`
      }
    }
  }
})
